<template>
  <div class="air-quality">
    <button
      class="btn btn_blue"
      :class="{'btn_dark': mode === 'night'}"
      @click="showAirQuality = !showAirQuality">
      {{ showAirQuality ? 'Hide' : 'Show' }} Air Quality
    </button>
    <table v-if="showAirQuality" class="air-quality__table" :class="{'air-quality__table_dark': mode === 'night'}">
      <th>Air Quality (index)</th>
      <th>NO<sub>2</sub></th>
      <th>PM<sub>2</sub></th>
      <th>O<sub>3</sub></th>
      <th>PM<sub>25</sub></th>

      <tr>
        <td>{{ airQualityNames[airState.main.aqi] }} ({{ airState.main.aqi }})</td>
        <td>{{ airState.components.no2 }}</td>
        <td>{{ airState.components.pm10 }}</td>
        <td>{{ airState.components.o3 }}</td>
        <td>{{ airState.components.pm2_5 }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAirQuality: false,
      airQualityNames: {
        1: 'Good',
        2: 'Fair',
        3: 'Moderate',
        4: 'Poor',
        5: 'Very Poor',
      }
    }
  },
  props: ['airState', 'mode'],
  name: 'AirQuality'
}
</script>

<style lang="scss" scoped>
@import "src/assets/main";

.air-quality {
  margin-top: 40px;

  &__table {
    margin: 20px auto 0 auto;
    max-width: 500px;
    width: 100%;
    padding: 20px;
    border-radius: 12px;
    box-shadow: rgb(50 50 93 / 20%) 0 2px 8px 0;
    background-color: $color-white;

    &_dark {
      background-color: $astral;
    }

    tr {
      line-height: 28px;
    }
  }
}
</style>