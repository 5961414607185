import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGoogleMaps from '@fawmi/vue-google-maps'

createApp(App)
  .use(store)
  .use(router)
  .use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyDgINz2pdpfBhRfVpXi9-uJLLNaHisBZ7Y',
      language: 'en',
    },
  })
  .mount('#app')
