<template>
  <div class="main" :class="{'main_dark': darkMode}">
    <div class="main__wrapper">
      <div class="main__mode" @click="changeMode">
        <img
          class="main__mode-icon"
          :src="require(`../assets/${modeIcon}.svg`)"
          alt="color mode"
        >
      </div>

      <main>
        <form @submit.prevent="getWeather" class="main__form">
          <div class="main__input">
            <label for="city">Enter city name</label>
            <input type="text" id="city" v-model="city">
          </div>
          <button
            @click="getWeather"
            type="button"
            class="btn"
            :class="{'btn_dark': darkMode}"
          >
            Show weather
          </button>
        </form>
        <Loader v-if="loading"/>
        <div
          v-else-if="cityError"
          class="error"
          :class="{'error_dark': darkMode}"
        >
          Sorry, the city not found :(
        </div>
        <div
          v-else-if="weatherObj && !cityError && !loading"
          class="weather"
        >
          <DateWeather :date="date"/>
          <CityWeather
            :weather-obj="weatherObj"
            :city="cityName"
            :cityObj="cityObj"
            @onShowOtherDates="onShowOtherDates"
            :showMore="showMore"
            :mode="mode"
          />
          <AirQuality :airState="airState" :mode="mode"/>

          <ul
            class="weather__list"
            v-if="daysForecast && showMore"
          >
            <DayWeatherItem
              v-for="day in days"
              :key="day" :day="day"
              :class="{active: checkIsActive(day), 'active_dark': checkIsActive(day) && darkMode}"
              @click="setActiveSlotObj(day)"
              :mode="mode"
            />
          </ul>
          <div class="weather__table-wrapper">
            <table
              class="weather__table"
              :class="{'weather__table_dark': darkMode}"
              v-if="daysForecast && showMore && activeWeatherSlot"
            >
              <tr>
                <th>Time</th>
                <th
                  v-for="param in weatherParams"
                  :key="param">
                  {{ param }}
                </th>
              </tr>

              <WeatherSlot
                v-for="slot in activeWeatherSlot"
                :key="slot.dt"
                :weather="slot"
                :mode="mode"
              />
            </table>
          </div>
        </div>
      </main>
    </div>
    <div class="map" :class="{'map_dark': darkMode}">
      <div id="map" class="map__leaflet"></div>
    </div>
  </div>
</template>

<script>
import CityWeather from "./CityWeather";
import DateWeather from "./DateWeather";
import Loader from "./Loader";
import DayWeatherItem from "./DayWeatherItem";
import WeatherSlot from "./WeatherSlot";
import AirQuality from "./AirQuality";

import L from 'leaflet';
import {weatherParams} from "../utils/weatherParams"

import weatherService from "../services/weatherService";

const {getCity, getData, getOtherDates, getAitPollution, getLocalData} = weatherService();

export default {
  name: 'MainPage',
  data() {
    return {
      city: '',
      cityObj: null,
      weatherObj: null,
      cityName: null,
      date: null,
      cityError: false,
      loading: false,
      daysForecast: null,
      showMore: false,
      activeWeatherSlot: null,
      weatherParams: weatherParams,
      airState: null,
      mode: 'day'
    }
  },
  components: {
    CityWeather,
    DateWeather,
    Loader,
    DayWeatherItem,
    WeatherSlot,
    AirQuality
  },
  computed: {
    daysObj() {
      const days = this.daysForecast.filter(item => item.dt_txt.includes('15:00:00'));
      days.shift();
      return days;
    },
    days() {
      return this.daysObj.map(item => item.dt_txt.slice(0, item.dt_txt.indexOf('15:00:00')).trim())
    },
    modeIcon() {
      return this.mode === 'day' ? 'sun' : 'moon';
    },
    map() {
      return L.map('map');
    },
    darkMode() {
      return this.mode === 'night';
    }
  },
  methods: {
    async getWeather() {
      if (!this.city) return;

      this.resetWeatherState();

      this.cityName = this.city;

      await getCity(this.city)
        .then(({data}) => {
          if (!data[0]) this.cityError = true;
          else this.cityObj = data[0];
        })
        .then(() => getData(this.cityObj.lat, this.cityObj.lon))
        .then(({data}) => this.weatherObj = data)
        .then(() => this.map.flyTo([this.cityObj.lat, this.cityObj.lon], 12, {animate: true, duration: 2}))
        .then(() => getAitPollution(this.cityObj.lat, this.cityObj.lon))
        .then(({data}) => this.airState = data.list[0])
        .catch((e) => console.log(e))
        .finally(() => this.loading = false);
    },

    resetWeatherState() {
      this.cityObj = null;
      this.cityError = false;
      this.loading = true;
      this.showMore = false;
      this.activeWeatherSlot = null;
    },

    async onShowOtherDates() {
      this.showMore = !this.showMore;
      if (!this.showMore) return;

      await getOtherDates(this.cityObj.lat, this.cityObj.lon)
        .then(res => this.daysForecast = res.data.list)
    },
    setActiveSlotObj(date) {
      const activeDate = this.daysForecast.filter(item => item.dt_txt.includes(date));
      this.activeWeatherSlot = activeDate.filter((item, i) => (i + 1) % 2 === 0);
    },
    checkIsActive(day) {
      return this.activeWeatherSlot && this.activeWeatherSlot[0] && this.activeWeatherSlot[0].dt_txt.includes(day);
    },
    changeMode() {
      this.mode = this.mode === 'day' ? 'night' : 'day';
    },
    async setMap() {
      const local = await getLocalData()

      const coords = local ? [local.lat, local.lon] : [51.505, -0.09];

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(this.map.setView(coords, 13));
    }
  },
  mounted() {
    this.setMap();
  }
}
</script>

<style lang="scss">
@import "src/assets/main";

.map {
  display: flex;
  justify-content: center;
  align-items: center;

  &__leaflet {
    height: 450px;
    width: 100vw;
  }

  &_dark {
    filter: brightness(0.9);
  }
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  color: $lunar-shadow;
  background-image: url("../assets/bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  &__wrapper {
    padding: 40px 0 40px 0;
    margin: 0 auto;

    @media (max-width: 1080px) {
      padding: 40px 20px 40px 20px;
    }

    @media (max-width: 400px) {
      padding: 40px 10px 40px 10px;
    }
  }

  &__mode {
    position: absolute;
    right: 40px;
    top: 80px;
    cursor: pointer;

    @media (max-width: 1080px) {
      right: 20px;
    }

    @media (max-width: 700px) {
      top: 20px;
    }
  }

  &__mode-icon {
    transition: .3s all;

    &:hover {
      transform: rotate(45deg);
    }
  }

  &__input {
    margin: 20px 15px 0 0;

    input {
      border: none;
      border-bottom: 1px solid $color-gray;
      background-color: transparent;
      padding: 5px 10px;
      width: 234px;
      color: $main-black;

      &:focus-visible {
        border: 1px solid $color-gray;
        box-shadow: rgb(217 219 240) 3px 3px 6px 0 inset, rgb(255 255 255 / 50%) -3px -3px 6px 1px inset;
        border-radius: 4px;
        outline: none;
      }

      @media (max-width: 420px) {
        margin: 20px 0;
      }
    }

    label {
      margin-bottom: 5px;
      display: block;
      font-size: 14px;
      text-align: left;
    }
  }

  &__form {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 0 auto;

    @media (max-width: 420px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &_dark {
    background-color: $main-dark;
    background-blend-mode: color;
    color: $color-white;

    input {
      color: $color-white;

      &:focus-visible {
        box-shadow: rgb(217 219 240 / 10%) 3px 3px 6px 0 inset, rgb(255 255 255 / 10%) -3px -3px 6px 1px inset;
      }
    }
  }
}

.weather {
  padding: 60px 0 30px 0;

  @media (max-width: 600px) {
    padding-top: 40px;
  }

  &__list {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 420px) {
      margin-top: 30px;
      flex-wrap: wrap;
    }

    @media (max-width: 360px) {
      justify-content: center;
    }
  }

  &__day {
    margin-top: 50px;
  }

  &__table-wrapper {
    margin: 60px auto 0 auto;
    overflow: auto;
    border-radius: 12px;

    @media (max-width: 760px) {
      max-width: 550px;
    }

    @media (max-width: 600px) {
      max-width: 340px;
    }
  }

  &__table {
    width: 100%;
    padding: 20px;
    border-radius: 12px;
    box-shadow: rgb(50 50 93 / 20%) 0 2px 8px 0;
    background-color: $color-white;

    @media (max-width: 1080px) {
      padding: 15px
    }

    th {
      padding: 0 7px;
      color: $lunar-shadow;
    }

    td {
      @media (max-width: 1080px) {
        padding: 0 5px;
      }
    }

    &_dark {
      background-color: $light-blue;

      th {
        color: $senate-blue;
      }
    }
  }
}
</style>
