<template>
  <div class="weather__wrapper" :class="{'weather__wrapper_dark': mode === 'night'}">
    <TodayWeatherBlock :mode="mode">
      City:
      <span>
        {{ capitalize(city) }} ({{ weatherObj.sys.country }})
        </span>
    </TodayWeatherBlock>
    <TodayWeatherBlock :mode="mode">
      <div
        class="descr"
        v-for="item in weatherObj.weather"
        :key="item.id">
         <span class="descr">
           {{ capitalize(item.description) }}
         </span>
        <div>
          <img
            class="icon"
            :src="`http://openweathermap.org/img/wn/${item.icon}@2x.png`"
            :alt="item.description">
        </div>
      </div>
    </TodayWeatherBlock>

    <TodayWeatherBlock :mode="mode">
      Cloudiness:
      <span>{{ weatherObj.clouds.all }}%</span>
    </TodayWeatherBlock>

    <TodayWeatherBlock :mode="mode">
      Temperature:
      <span>{{ countTemp(this.weatherObj.main.temp) }}°C</span>
    </TodayWeatherBlock>

    <TodayWeatherBlock :mode="mode">
      Feels like:
      <span>{{ countTemp(this.weatherObj.main.feels_like) }}°C</span>
    </TodayWeatherBlock>

    <TodayWeatherBlock :mode="mode">
      Humidity:
      <span>{{ weatherObj.main.humidity }}%</span>
    </TodayWeatherBlock>

    <TodayWeatherBlock :mode="mode">
      Wind:
      <span>{{ weatherObj.wind.speed }} m/s</span>
    </TodayWeatherBlock>

    <TodayWeatherBlock :mode="mode">
      Pressure:
      <span>{{ weatherObj.main.pressure }} hPa</span>
    </TodayWeatherBlock>

    <button
      @click="$emit('onShowOtherDates')"
      class="btn btn_center" :class="{'btn_dark': mode === 'night'}">
      {{ showMore ? 'Hide other days' : 'Show other days' }}
    </button>
  </div>
</template>

<script>
import TodayWeatherBlock from "./TodayWeatherBlock";

export default {
  name: 'CityWeather',
  props: ['weatherObj', 'city', 'showMore', 'mode'],
  emits: ['onShowOtherDates'],
  components: {
    TodayWeatherBlock
  },
  methods: {
    countTemp(temp) {
      const temperature = Math.floor(temp)
      return temperature > 0 ? `+${temperature}` : temperature;
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/main";

.weather {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 40px;

    @media (max-width: 1080px) {
      grid-gap: 15px;
    }

    @media (max-width: 700px) {
      grid-template-columns: repeat(2, 1fr);
    }

    span {
      margin-top: 10px;
      font-weight: bold;
      font-size: 16px;
      color: $main-blue;
    }

    &_dark {
      span {
        color: $dark-blue;
      }
    }
  }

  .descr {
    position: relative;
    top: 10px;
  }
}
</style>