<template>
  <tr class="weather-slot">
    <td class="weather-slot__time" :class="{'weather-slot__time_dark': mode === 'night'}">
      {{ weather.dt_txt.slice(11, 16) }}
    </td>
    <WeatherSlotItem :mode="mode">
      {{ tempFormatted(weather.main.temp) }}°C
    </WeatherSlotItem>

    <WeatherSlotItem :mode="mode">
      {{ tempFormatted(weather.main.feels_like) }}°C
    </WeatherSlotItem>

    <WeatherSlotItem :mode="mode">
      {{ weather.main.humidity }}%
    </WeatherSlotItem>

    <WeatherSlotItem :mode="mode">
      {{ weather.main.pressure }} hPa
    </WeatherSlotItem>

    <WeatherSlotItem :mode="mode">
      {{ weather.clouds.all }}%
    </WeatherSlotItem>

    <WeatherSlotItem :mode="mode">
      {{ Math.floor(weather.wind.speed) }} m/s
    </WeatherSlotItem>

    <WeatherSlotItem :mode="mode">
      {{ weather.weather[0].description }}
    </WeatherSlotItem>

    <WeatherSlotItem :mode="mode">
      <img
        :src="`http://openweathermap.org/img/wn/${weather.weather[0].icon}.png`"
        :alt="weather.weather[0].description">
    </WeatherSlotItem>

  </tr>
</template>

<script>
import WeatherSlotItem from "./WeatherSlotItem";

export default {
  name: 'WeatherSlot',
  props: ['weather', 'mode'],
  components: {
    WeatherSlotItem
  },
  methods: {
    tempFormatted(temp) {
      return temp > 0 ? '+' + Math.floor(temp) : Math.floor(temp);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/main";

.weather-slot {
  &__time {
    margin-right: 30px;
    color: $lunar-shadow;

    &_dark {
      color: $senate-blue;
    }
  }
}
</style>