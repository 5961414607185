<template>
  <h4 class="date-title">
    Weather forecast for <span>{{ dateLocal }} (GMT+3)</span>
  </h4>
</template>

<script>
export default {
  name: 'DateWeather',
  data() {
    return {
      dataToday: new Date()
    }
  },
  computed: {
    dateLocal() {
      return this.dataToday.toLocaleString()
    }
  }
}
</script>

<style lang="scss" scoped>
.date-title {
  font-weight: normal;

  span {
    font-weight: bold;
  }
}
</style>