<template>
  <li
    class="weather-slot"
    :class="{'weather-slot_dark': mode === 'night'}"
  >
    {{ date }} <br>
    <div class="weather-slot__day">{{ weekDay }}</div>
  </li>
</template>

<script>
import {week} from '../utils/daysOfWeek';

export default {
  name: 'DayWeatherItem',
  props: ['day', 'mode'],
  computed: {
    weekDay() {
      return week[new Date(this.day).getDay()];
    },
    date() {
      return this.day.slice(5, this.day.length).split('-').join('.');
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/main";

.weather-slot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  box-shadow: rgb(50 50 93 / 20%) 0 2px 9px 0;
  font-size: 18px;
  cursor: pointer;
  transition: .3s all;
  background-color: $color-white;

  &:hover {
    box-shadow: rgb(10 50 93 / 15%) 0 1px 1px 0;
  }

  @media (max-width: 600px) {
    &:hover {
      box-shadow: rgb(10 50 93 / 15%) 0 1px 5px 0;
    }
  }

  @media (max-width: 420px) {
    width: 75px;
    height: 75px;
    margin: 5px 0;
  }

  @media (max-width: 360px) {
    margin: 5px;
  }

  &__day {
    font-size: 14px;
  }

  &_dark {
    background-color: $light-blue;
    color: $dark-blue;

    &:hover {
      box-shadow: rgb(60 70 90 / 50%) 0 0 10px 10px;
      background-color: $pre-school;
    }

    @media (max-width: 600px) {
      &:hover {
        background-color: $light-blue;
        box-shadow: rgb(10 50 93 / 15%) 0 1px 5px 0;
      }
    }
  }
}

.active {
  background-color: $alaskan-ice;
  color: white;
  box-shadow: none;

  &:hover {
    background-color: $alaskan-ice-light;
  }

  @media (max-width: 600px) {
    &:hover {
      background-color: $alaskan-ice;
    }
  }

  &_dark {
    background-color: $astral;
    color: white;

    &:hover {
      background-color: $hippie-blue;
    }

    @media (max-width: 600px) {
      &:hover {
        background-color: $astral;
      }
    }
  }
}
</style>