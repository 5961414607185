<template>
  <MainPage/>
</template>

<script>
import MainPage from "./components/MainPage";

export default {
  components: {
    MainPage
  },
}
</script>

<style lang="scss">
@import "src/assets/main";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: $main-black;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

body, h1 {
  margin: 0;
}
</style>
