<template>
  <svg class="loader" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="64px" height="64px" viewBox="0 0 128 128"
       xml:space="preserve"><g><path d="M64 16.5A66.53 66.53 0 0 0 .26 64a63.75 63.75 0 0 1 127.5 0h-.02A66.53 66.53 0 0 0 64 16.5z" fill="#7b9cbd"/><animateTransform
    attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1200ms"
    repeatCount="indefinite"></animateTransform></g>
  </svg>
</template>

<script>

export default {
  name: 'Loader'
}
</script>

<style lang="scss" scoped>
.loader {
  margin-top: 60px;
}
</style>