<template>
  <td class="slot" :class="{'slot_dark': mode === 'night'}">
    <slot></slot>
  </td>
</template>

<script>
export default {
  name: 'WeatherSlotItem',
  props: ['mode']
}
</script>

<style lang="scss" scoped>
@import "../assets/main";

.slot {
  color: $main-blue;

  &_dark {
    color: $dark-blue;
  }
}
</style>