import axios from "axios";

const weatherService = () => {
  const _apiKey = 'd4e15d791d1f456765e48e3e2d98cf11';
  const _apiBase = 'https://api.openweathermap.org/';

  const getCity = async (city_name = '', state_code = '', country_code = '', limit = '') => {
    const url = `${_apiBase}geo/1.0/direct?q=${city_name},${state_code},${country_code}&limit=${limit}&appid=${_apiKey}`;
    return axios.get(url);
  }

  const getData = async (lat = '', lon = '', unit = 'metric') => {
    const url = `${_apiBase}data/2.5/weather?lat=${lat}&lon=${lon}&units=${unit}&appid=${_apiKey}`;

    return axios.get(url);
  }

  const getOtherDates = async (lat = '', lon = '', unit = 'metric') => {
    const url = `${_apiBase}data/2.5/forecast?lat=${lat}&lon=${lon}&units=${unit}&appid=${_apiKey}`;
    return axios.get(url);
  }

  const getAitPollution = async (lat = '', lon = '') => {
    const url = `${_apiBase}data/2.5/air_pollution?lat=${lat}&lon=${lon}&appid=${_apiKey}`;
    return axios.get(url);
  }

  const getLocalData = async () => {
    return await axios.get('http://ip-api.com/json/').then(response => response.data);
  }

  return {getData, getCity, getOtherDates, getAitPollution, getLocalData}
}

export default weatherService;