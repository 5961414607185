<template>
  <div
    class="weather-block"
    :class="{'weather-block_dark': mode === 'night'}"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'TodayWeatherBlock',
  props: ['mode']
}
</script>

<style lang="scss" scoped>
@import "../assets/main";

.weather-block {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 12px;
  box-shadow: rgba(50, 50, 93, 0.2) 0 2px 8px 0;
  font-size: 14px;
  color: $main-black;
  background-color: $color-white;

  &_dark {
    background-color: $light-blue;

    span {
      color: $dark-blue;
    }
  }

  .icon {
    max-width: 100px;
    height: auto;
  }

}
</style>